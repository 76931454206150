//
// Navbar
//

@import "../core/navbars/navbar";
@import "../core/navbars/navbar-vertical";
@import "../core/navbars/navbar-search";
@import "../core/navbars/navbar-dropdown";
@import "../core/navbars/navbar-collapse";

.navbar-vertical.navbar-expand-md {
  @include media-breakpoint-up(sm) {
    overflow-y: auto;
  }
}
