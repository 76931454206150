.navbar .collapsing .navbar-toggler {
  pointer-events: none;
}

.navbar .nav-item .nav-link-icon.nav-link i {
  margin-right: 4px;
}

.navbar-vertical .navbar-nav .nav-link>i {
  font-size: 1rem;
}
