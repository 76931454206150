//
// List group
//

@import "../core/list-groups/list-group";

ul.taglist {
  list-style: none;
  padding-inline-start: 20px;
  margin-bottom: initial;

  &[title]::before {
    content: attr(title);
    padding-right: 0.5rem;
    font-weight: 800;
    font-size: 0.75rem;
  }

  &.bold-title[title]::before {
    font-size: 0.8rem;
    font-weight: 600;
  }

  li {
    display: inline-block;
    white-space: pre-wrap;
    font-size: 0.75rem;
    font-weight: 350;
    line-height: 1.7;

    &:not(:last-child):after {
      content: ", ";
    }
  }
}


ul.filter {
  padding-inline-start: 20px;
  list-style: none;
  margin-bottom: initial;

  &[title]::before {
    content: attr(title);
    padding-right: 0.5rem;
    font-weight: 800;
    font-size: 0.75rem;
  }

  &.bold-title[title]::before {
    font-size: 0.8rem;
    font-weight: 600;
  }
}