//
// Button
//

@import "../core/buttons/button";
@import "../core/buttons/button-icon";
@import "../core/buttons/button-brand";

// Counteract the margin-right: 4px for .btn-inner--icon
.btn-inner--icon i:not(.fa) {
  left: 2px;
}
