.table {
  .avatar-group .avatar {
    margin-right: 4px;
  }

  .badge i {
    margin-right: 10px;
  }
}


// OVERRIDE STYLES FOR react-data-table-component !!!

// Offset for last col as it gets clipped with horizontal scroll
.rdt_Table {
  //padding-right: 15px;
}

// Header wrapper
.rdt_TableHeadRow {
  background-color: #f6f9fc !important;
  // Offset cos of the Card Header alignment
  padding-left: 5px;
}

// Table col headings
.rdt_TableCol {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .65rem !important;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: #8898aa !important;
}

// Table Pagination
.rdt_Pagination {

  // Fix icon for rows per page selector - it breaks if options are > 99 or have 3x0's
  svg:first-of-type {
    right: -6px;
  }
}
