// Differences from the HTML to the React product

// react plugins
@import "plugins/plugin-react-datetime";
// core components
@import "buttons";
@import "mixins";
@import "navbar-dropdown";
@import "navbar";
@import "tables";


.react-datepicker-wrapper {
  width: 100%;
}


.datepickerCustomStyle {
  .react-datepicker__header {
    background-color: #ffffff;
  }

  .react-datepicker__day--selected {
    background-color: #5e72e4;
  }

  .react-datepicker__navigation--previous {
    border-right-color: theme-color("primary");
  }

  .react-datepicker__day {
    font-weight: 300;
  }
}

.uploadDropzone {
  &:hover {
    border-color: $primary !important;
  }

  &:active {
    border-color: $primary !important;
  }
}


// Recharts legend
.recharts-legend-item {
  font-size: 0.9rem;
}

.recharts-legend-item-text {
  text-transform: capitalize;
}

.recharts-custom-tooltip {
  background: #e9ecef;
  border: 1px solid #ced4da;
  opacity: 0.95;
  padding: 1rem 2rem;

  p {
    font-weight: 500;
    text-transform: capitalize;
    color: #000;

    &.title {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
